.about-background {
    width: 100%;
    padding: 50px 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.4) 30%, rgba(0, 0, 0, 0.3) 60%, rgba(0, 0, 0, 0.2) 100%);
    margin: 3.125rem 0;
}

.about-container {
    max-width: 81.25rem;
    margin: 0 auto;
    padding: 20px;
    color: white;
}

.about-title {
    font-family: 'Barlow', sans-serif;
    font-size: 3.75rem;
    margin-bottom: 20px;
    text-align: center;
}

.about-text {
    font-family: 'Barlow', sans-serif;
    font-size: 1.875rem;
    font-weight: bold;
    line-height: 1.2;
    text-align: center;
}

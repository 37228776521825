.home-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.background-img {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    padding-top: 10.9375rem;
    transition: transform 0.5s ease;
}

.image-link:hover .background-img {
    transform: scale(1.02);
}

.content {
    position: relative;
    padding: 20px;
}

.content h1 {
    font-family: 'Barlow', sans-serif;
    font-size: 2rem;
    margin-bottom: 20px;
}

.content p {
    font-family: 'Barlow', sans-serif;
    font-size: 1rem;
    margin-bottom: 15px;
}

header {
    background-color: #1a1a1a;
    padding: 20px;
    text-align: center;
    font-family: 'Barlow', sans-serif;
    font-weight: bold;
    font-size: x-large;
}

header img {
    max-width: 200px;
}

.header-container {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
    transition: background-color 0.3s, padding 0.3s;
    z-index: 1000;
    font-family: 'Barlow', sans-serif;
    padding: 10px 20px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
}

.header-container.scrolled {
    padding: 10px 0;
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    transition: padding 0.3s;
}

.logo {
    max-width: 150px;
    transition: max-width 0.3s;
}

.header-container.scrolled .logo {
    max-width: 100px;
}

nav .nav-links {
    list-style: none;
    display: flex;
    gap: 6.25rem;
    letter-spacing: .25rem;
}

.nav-links li a {
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    transition: color 0.3s;
    font-family: 'Barlow', sans-serif;
}

.nav-links li a:hover {
    color: #ff4081;
}

.social-nav {
    display: flex;
    align-items: center;
    padding: .625rem 2.1875rem;
    border: 2px solid white;
    border-radius: 30px; 
}

.social-text {
    color: white;
    font-size: 1rem;
    margin-right: 15px;
    letter-spacing: .1875rem;
}

.social-icons {
    display: flex;
    gap: 10px;
}

.social-icons a img {
    width: 24px;
    height: 24px;
    transition: transform 0.3s;
}

.social-icons a img:hover {
    transform: scale(1.2); /* Effet d'agrandissement au survol */
}

/* Supprime le dernier margin-right pour éviter un espace après la dernière icône */
.social-icons a:last-child {
    margin-right: 0;
}
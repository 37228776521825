.footer-container {
    background-color: #000000;
    color: white;
    max-width: 100%;
    box-sizing: border-box;
    position: relative;
}

footer {
    position: relative;
    bottom: 0;
    width: 100%;
    font-family: 'Barlow', sans-serif;
}

.footer-left {
    flex: 1;
}

.footer-logo {
    max-width: 15.625rem;
}

.footer-center {
    flex: 1;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
}

.footer-right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    gap: .625rem;
    font-weight: bold;
    font-size: 1.5rem;
    align-items: center;
}

.footer-right a img {
    width: 2.8125rem;
    height: 2.8125rem;
    transition: transform 0.3s;
}

.footer-right a img:hover {
    transform: scale(1.2);
}

.footer-bottom {
    padding: 10px;
    font-size: 0.9rem;
    padding-left: 1.5%;
}

.footer-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;700&display=swap');

body {
  font-family: 'Barlow', sans-serif;
  margin: 0;
  padding: 0;
  background-image: url('./assets/images/bg_tile.jpeg');
  background-repeat: repeat;
  background-size: 400px 400px;
  background-attachment: fixed;
  background-color: #f0f0f0;
  height: 100%;
}

.main-content {
  flex: 1;
}

.main-content h1 {
  color: #ff4081;
}

.button-primary {
  background-color: #ff4081;
  color: white;
  border: none;
  padding: 10px 20px;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button-primary:hover {
  background-color: #e0356b;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
